import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',

    component: () => import(/* webpackChunkName: "about" */ '../views/ElementView.vue')
  },  
  {
    path: '/fxwz',
    name: 'fxwz',

    component: () => import(/* webpackChunkName: "about" */ '../views/share/ShareWebsite.vue')
  },


  {
    path: '/',
    redirect: 'home',
  //  component: () => import(/* webpackChunkName: "about" */ '../views/Elenvue.vue.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router
